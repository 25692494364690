<template>
  <v-tooltip
    bottom
    max-width="350px"
    :disabled="disabled"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        class="mx-2"
        v-on="on"
      >
        <v-icon color="error">
          mdi-alert-circle-outline
        </v-icon>
      </v-btn>
    </template>
    <span>LivePerson Products/Services are unavailable or severely degraded and have a significant impact on the customer's business functions and practices. A workaround is not available.</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Downtime',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
