<template>
  <v-row class="pa-md-4 pa-2">
    <v-col cols="12" justify="center">
      <v-row>
        <v-col cols="12" md="1">
          <v-btn link text color="secondary" @click="goBack">
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-col>
      </v-row>

      <div class="glass-block pa-5" v-if="this.maintenance">
        <v-row class="justify-center" >
            <v-col cols="2">
              <maintenance-icon/>
            </v-col>
          </v-row>
        <v-row class="justify-center">
          <v-col cols="10" >
            <h3>{{this.maintenance.subject ? this.maintenance.subject : this.maintenance.activity}}</h3>
          </v-col>
        </v-row>
        <v-row class="justify-center mb-5">
          <v-col cols="10" >
            <h5>
              Current Status: {{this.maintenance.status}} |
              <span v-if="this.maintenance.status === 'Completed'">Completed Status: {{ this.maintenance.sub_status }} | </span>
              <span v-if="!['Completed', 'Cancelled'].includes(this.maintenance.status)">Next Update: {{ this.maintenance.next_update }} | </span>
              Service Impacted: {{this.maintenance.service_impacted.replaceAll(',', ', ')}} |
              <span>Start Date: {{ getDate(this.maintenance.start_date) }} | </span>
              <span>Duration: {{ this.maintenance.duration.hours}} Hours {{this.maintenance.duration.minutes ? `and ${this.maintenance.duration.minutes} Minutes` : '' }} | </span>
              <span v-if="this.maintenance.support_case">Support case: {{ this.maintenance.support_case }}</span>
            </h5>
          </v-col>
        </v-row>
        <template v-if="this.maintenance.status === 'Cancelled'">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="3" class="font-weight-bold">
                Cancellation Reason
              </v-col>
              <v-col cols="12" md="9">
                  {{ this.maintenance.cancel_reason }}
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-if="this.maintenance.bridge">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="3" class="font-weight-bold">
                LivePerson Bridge
              </v-col>
              <v-col cols="12" md="9">
                <a :href="`${this.maintenance.bridge}`" target="_blank">
                  {{ this.maintenance.bridge }}
                </a>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <v-col cols="12">

            <v-row v-if="this.maintenance.subject">
              <v-col cols="12" md="3" class="font-weight-bold">
                Activity
              </v-col>
              <v-col cols="12" md="9">
                  {{ this.maintenance.activity }}
              </v-col>
            </v-row>
          <v-row>
            <v-col cols="12" md="3" class="font-weight-bold">
              Impact On Service
            </v-col>
            <v-col cols="12" md="9">
              {{ this.maintenance.impact }}
            </v-col>
          </v-row>
          <v-row v-if="this.maintenance.who_is_impacted">
            <v-col cols="12" md="3" class="font-weight-bold">
              Who is impacted
            </v-col>
            <v-col cols="12" md="9">
              {{ this.maintenance.who_is_impacted }}
            </v-col>
          </v-row>

          <v-row v-if="this.maintenance.reason">
            <v-col cols="12" md="3" class="font-weight-bold">
              Reason
            </v-col>
            <v-col cols="12" md="9">
              {{ this.maintenance.reason }}
            </v-col>
          </v-row>


          </v-col>
        <v-col v-if="this.maintenance.status === 'Completed' || this.maintenance.updates.length" cols="12" >
          <v-divider class="my-8"></v-divider>
          <p class="text-h6 font-weight-bold">
            History for this Maintenance
          </p>
          <div v-if="this.maintenance.status === 'Completed'">
            <v-row  class="py-2">
              <v-col cols="3">
                {{ getDate(this.maintenance.end_date) }}
              </v-col>
              <v-col cols="9" >
                <div class="font-weight-bold" >Complete Actions</div>
                <div>{{ this.maintenance.complete_actions }}</div>
              </v-col>
            </v-row>
          </div>
          <div v-if="this.maintenance.updates && this.maintenance.updates.length">
            <v-row  class="py-2" v-for="(update, index) in this.maintenance.updates.slice().reverse()" :key="index">
              <v-col cols="3">
                {{ getDate(update.time) }}
              </v-col>
              <v-col cols="9" >
                <div>
                  <h4>What's been completed: </h4>
                  {{ update.actionTaken }}
                </div>
                <div>
                  <h4>Expected implications: </h4>
                  {{ update.limitations }}
                </div>
                <div>
                  <h4>Next steps: </h4>
                  {{ update.nextUpdate }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>

      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import maintenanceIcon from '@/components/tooltipIcons/maintenanceIcon'

export default {
  name: 'Maintenance',
    components: {
      maintenanceIcon,
    },

  data: () => ({
    maintenance: null
  }),

  async created () {
    try {
      this.setLoading(true)
      this.maintenance = await this.fetchMaintenanceData(this.maintenanceId)
    }catch (err){
      await this.showPopup('Error loading maintenance data')
    }finally {
      this.setLoading(false)
    }
  },
  computed: {
    ...mapGetters('Site', ['getTimeZone']),
    maintenanceId () {
      return this.$route.params.id || null
    },
    siteId () {
      return this.$route.params.site || null
    },
    timezone(){
      return this.getTimeZone
    },
  },

  methods: {
    ...mapActions('Tools', [ 'showPopup']),
    ...mapMutations('Tools', [ 'setLoading']),
    ...mapActions('Site', ['fetchMaintenanceData']),

    getDate (dateTs) {
      return this.$moment.tz(dateTs * 1000, this.timezone).format('YYYY-MM-DD hh:mm A')
    },

    goBack(){
      switch (this.$route.name) {
        case 'Current Maintenance':
          return this.$router.push(`/current/${this.siteId}`)
        case 'Planned Maintenance':
          return this.$router.push(`/futuremaintenance/${this.siteId}`)
        case 'Dashboard Maintenance':
          return this.$router.push(`/dashboard/${this.siteId}`)
        default:
          return this.$router.push(`/history/${this.siteId}`)
      }
    },

  }
}

</script>

<style scoped lang="scss">


</style>
