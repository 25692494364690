<template>
  <v-tooltip
    bottom
    max-width="350px"
    :disabled="disabled"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        class="mx-2"
        v-on="on"
      >
        <v-icon color="error lighten-2">
          mdi-numeric-3-box-outline
        </v-icon>
      </v-btn>
    </template>
    <span>LivePerson Products/Services are available, but Third Party issues may be causing impact to the customer's ability to use our services. The impact of these issues may vary from severe to minimal. Third Party issues include but are not limited to: ISP/Backbone outages, SMS Carrier issues, and wide-spread Power Outages</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'thirdparty',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
