<template>
  <v-row class="pa-md-4 pa-2">
    <v-col cols="12" justify="center">
      <v-row>
        <v-col cols="12" md="1">
          <v-btn link text color="secondary" @click="goBack">
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-col>
      </v-row>

      <div class="glass-block pa-5" v-if="this.incident">
        <v-row class="justify-center" >
          <v-col cols="2">
            <component :is="dictionary[this.incident.incident_type]" />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" >
            <h3>{{this.incident.impact}}</h3>
          </v-col>
        </v-row>
        <v-row class="justify-center mb-5">
          <v-col cols="10" >
            <h5>
              Current Status: {{this.incident.status}} |
              Service Impacted: {{this.incident.service_impacted.replaceAll(',', ', ')}} |
              <span>Started on: {{ getDate(this.incident.start_date) }} | </span>
              <span v-if="incident.status !== 'New'">{{ getLastUpdate() }} | </span>
              <span v-if="this.incident.support_case">Support case: {{ this.incident.support_case }}</span>
            </h5>
          </v-col>
        </v-row>


        <template v-if="this.incident.bridge">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="3" class="font-weight-bold">
                LivePerson Bridge
              </v-col>
              <v-col cols="12" md="9">
                <a class="bridgeLink" :href="`${this.incident.bridge}`" target="_blank">
                  {{ this.incident.bridge }}
                </a>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-if="[this.$constants.STATUS.prca, this.$constants.STATUS.prca_update, this.$constants.STATUS.rca].includes(this.incident.status) && this.incident.revision_id">
          <v-col cols="12">
            <v-row justify="center">
              <v-col cols="12" md="3" class="font-weight-bold">
                {{this.preliminary ? 'Preliminary ' : ''}}Root Cause Analysis
              </v-col>
              <v-col cols="12" md="9">
                A root-cause analysis for this issue has been performed and is available
              </v-col>
              <v-col cols="12"  class="ma-0 pa-0" md="5">
                <span>
                  <v-btn :small="$vuetify.breakpoint.mobile" class="mx-2" :loading="loading" v-if="preliminary" @click="downloadRCA('prca')" outlined dense >
                  Download PRCA
                  <v-icon class="ml-2">mdi-download</v-icon>
                </v-btn>
                  <v-btn :small="$vuetify.breakpoint.mobile" class="mx-2" :loading="loading" v-if="!preliminary" @click="downloadRCA('rca')" outlined dense >
                  Download RCA
                  <v-icon class="ml-2">mdi-download</v-icon>
                </v-btn>
                  </span>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <template v-if="[this.$constants.STATUS.prca, this.$constants.STATUS.prca_update, this.$constants.STATUS.rca].includes(this.incident.status) && !this.incident.revision_id">
          <v-col cols="12">
            <v-row justify="center">
              <v-col cols="12" class="font-weight-bold">
                {{this.preliminary ? 'Preliminary ' : ''}}Root Cause Analysis
              </v-col>
              <v-col cols="12" md="3" class="font-weight-bold">
                Root Cause
              </v-col>
              <v-col cols="12" md="9">
                {{ this.incident.root_cause }}
              </v-col>
              <v-col cols="12" md="3" class="font-weight-bold">
                Corrective Actions
              </v-col>
              <v-col cols="12" md="9">
                {{ this.incident.corrective_actions }}
              </v-col>
            </v-row>
          </v-col>
        </template>

        <template v-else-if="this.incident.status === 'Resolved'">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="3" class="font-weight-bold">
                Preliminary RCA
              </v-col>
              <v-col cols="12" md="9">
                {{ getSLA() }}
              </v-col>
            </v-row>
          </v-col>
        </template>

        <v-col v-if="['Resolved', 'PRCA', 'RCA'].includes(this.incident.status) || this.incident.updates.length" cols="12" >
          <v-divider class="my-8"></v-divider>
          <p class="text-h6 font-weight-bold">
            History for this incident
          </p>
          <div v-if="['Resolved', 'PRCA', 'RCA'].includes(this.incident.status)">
            <v-row  class="py-2">
              <v-col cols="3">
                {{ getDate(this.incident.end_date) }}
              </v-col>
              <v-col cols="9" >
                <div class="font-weight-bold" :class="$constants.RecoveryColorClass['Full system recovery']">Full system recovery</div>
                <div>{{ this.incident.resolution_note }}</div>
              </v-col>
            </v-row>
          </div>
          <div v-if="this.incident.updates && this.incident.updates.length">
            <v-row  class="py-2" v-for="(update, index) in this.incident.updates.slice().reverse()" :key="index">
              <v-col cols="3">
                {{ getDate(update.time) }}
              </v-col>
              <v-col cols="9" >
                <div v-if="update.recovery_status" class="font-weight-bold" :class="$constants.RecoveryColorClass[update.recovery_status]">{{update.recovery_status}}</div>
                <div>{{ update.actionTaken }}</div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import degradation from '@/components/tooltipIcons/degradation'
import thirdparty from '@/components/tooltipIcons/thirdparty'
import downtime from '@/components/tooltipIcons/downtime'
import maintenance from '@/components/tooltipIcons/maintenanceIcon'
import suspected from '@/components/tooltipIcons/suspected'

export default {
  name: 'Incident',
  components: {
    degradation,
    thirdparty,
    downtime,
    maintenance,
    suspected
  },

  data: () => ({
    dictionary: {
      'Degradation': degradation,
      '3rd Party': thirdparty,
      'Downtime': downtime,
      'Maintenance':maintenance,
      'Suspected': suspected,
    },
    preliminary: false,
    incident: null,
    loading: false
  }),

  async created () {
    try {
      this.setLoading(true)
      this.incident = await this.fetchIncidentData(this.interruptionId)
      this.preliminary = ['PRCA', 'PRCA-UPDATE'].includes(this.incident.status)
      if (['PRCA', 'PRCA-UPDATE', 'RCA'].includes(this.incident.status) && Array.isArray(this.incident.rca)){
        this.incident.prca = this.incident.rca.find(x=> x.type === 'prca')
        this.incident.rca = this.incident.rca.find(x=> x.type === 'rca')
      }
    }
    catch (err){
      console.error(err)
      this.showPopup('Error loading incident data')
    }
    finally {
      this.setLoading(false)
    }

  },
  computed: {
    ...mapGetters('Site', ['getTimeZone']),
    interruptionId () {
      return this.$route.params.id || null
    },
    siteId () {
      return this.$route.params.site || null
    },
    timezone(){
      return this.getTimeZone
    },
  },

  methods: {
    ...mapActions('Site', ['fetchIncidentData']),
    ...mapActions('Tools', [ 'showPopup']),
    ...mapMutations('Tools', [ 'setLoading']),
    getDate (dateTs) {
      return this.$moment.tz(dateTs * 1000, this.timezone).format('YYYY-MM-DD hh:mm A')
    },

    getLastUpdate () {
      let time;
      switch (this.incident.status){
        case 'PRCA':
        case 'PRCA-UPDATE':
        case 'RCA':
        case 'Resolved':
          time = this.getDate(this.incident.end_date)
          return `Ended on: ${time}`
        case 'In Progress':
          time = this.getDate(this.incident.updates.length ? this.incident.updates[this.incident.updates.length - 1].time
              : this.incident.start_date)
          return `Last updated at: ${time}`
      }
    },

    async downloadRCA (type) {
      try {
        this.loading = true
        const rcaId = type === 'rca' ?
            this.incident.rca.id :
            this.incident.prca.id
        const payload = {
          id: rcaId,
          timezone: this.timezone
        }
        await this.$store.dispatch('Site/downloadRCA', payload)
      }catch (err){
        this.showPopup('Error downloading incident rca')
      }finally {
        this.loading = false
      }

    },
    goBack(){
      switch (this.$route.name) {
        case 'Current Incident':
          return this.$router.push(`/current/${this.siteId}`)
        case 'History Incident':
          return this.$router.push(`/history/${this.siteId}`)
        default:
          return this.$router.push(`/dashboard/${this.siteId}`)
      }
    },
    getSLA(){
      if (this.incident.rca_sla === 'NO RCA.') return 'No RCA Planned'
      const sla = this.incident.severity === 'SEV1' ? 2 :
          this.incident.severity === 'SEV2' ? 3 : 0
      return sla > 0  ? `Within ${sla} business days of resolution` :
          'No RCA Planned'
    }

  }

}

</script>

<style scoped lang="scss">
.bridgeLink{
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

</style>
