<template>
  <v-tooltip
    bottom
    max-width="350px"
    :disabled="disabled"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        class="mx-2"
        v-on="on"
      >
        <v-icon color="info">
          mdi-clock-outline
        </v-icon>
      </v-btn>
    </template>
    <span>Scheduled maintenance activity</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Maintenance-icon',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
